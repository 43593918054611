import React from 'react';
import AudioPlayer from '../../services/AudioPlayer';
import classNames from 'classnames';
import './style.scss';

const Button = (props) => {
  function handleOnClick() {
    AudioPlayer.playButtonClickSound();
    props.onClick();
  }

  const classes = classNames({
    button__wrapper: true,
    pulse: props.pulse,
  });

  return (
    <div className={classes}>
      <button className="button" onClick={handleOnClick}>
        <span className="button__text">{props.children}</span>
      </button>
    </div>
  );
};

export default Button;
