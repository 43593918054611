export type Question = {
  id: number;
  person?: string;
  personKey?: string;
  title: string;
  text: string;
  answerA: {
    text: string;
    impact: string;
    values: [
      {
        type: string;
        value: number;
      }
    ];
    visualChange?: string;
  };
  answerB: {
    text: string;
    impact: string;
    values: [
      {
        type: string;
        value: number;
      }
    ];
    visualChange?: string;
  };
  condition: string | null;
  played?: boolean;
};

export type Answer = {
  text: string;
  impact: string;
  values: [
    {
      type: string;
      value: number;
    }
  ];
  visualChange?: string;
};

export type QuestionValues = [
  {
    type: string;
    value: number;
  }
];

export type StoreValues = {
  relation: number;
  safety: number;
  wealth: number;
  happiness: number;
  reputation: number;
};

export type Condition = {
  text: string;
  conditions: [
    {
      type: string;
      value: number;
      evaluation: string;
    }
  ];
};

export type Evaluation = {
  type: string;
  value: number;
  evaluation: string;
};

const SHOW_INTRO = 'SHOW_INTRO';
const SHOW_GAME = 'SHOW_GAME';
const SHOW_INTERMEDIATE = 'SHOW_INTERMEDIATE';
const SHOW_MAP = 'SHOW_MAP';
const SHOW_RESULT = 'SHOW_RESULT';
const SHOW_ALERT = 'SHOW_ALERT';

export const GameStates = {
  SHOW_INTRO,
  SHOW_GAME,
  SHOW_INTERMEDIATE,
  SHOW_MAP,
  SHOW_RESULT,
  SHOW_ALERT
}
