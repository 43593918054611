import React from 'react';
import { observer } from 'mobx-react';
import Button from '../../components/Button';
import '../../theme/_overlay.scss';

const GloseGameAlert = observer((props) => {
  function handleAlertCloseClick() {
    props.closeAlert();
  }

  function handleGameCloseClick() {
    props.toHome();
  }

  const style = props.show ? { display: 'flex' } : { display: 'none' };

  return (
    <div className="overlay" style={style}>
      <div className="overlay__content-wrapper">
        <h1 className="overlay__headline" size={1.4}>
          Spiel abbrechen?
        </h1>
        <p className="overlay__text">
          Dein Fortschritt wird nicht gespeichert.
        </p>
        <div className="overlay__button-wrapper">
          <Button onClick={handleGameCloseClick}>Spiel abbrechen</Button>
          <Button onClick={handleAlertCloseClick}>Weiterspielen</Button>
        </div>
      </div>
    </div>
  );
});

export default GloseGameAlert;
