import csvLoader from './csvLoader';
import { Question, QuestionValues } from '../stores/types';

class QuestionParser {
  async getQuestionData(url: string) {
    const content = await csvLoader.getCsvData(url);
    return this.parseQuestions(content.data);
  }

  parseQuestions = (questionData: any) => {
    let questions: Question[] = [];

    questionData.forEach((data: any) => {
      let question: Question = {
        id: data.id,
        title: data.questionTitle,
        text: data.questionText,
        played: false,
        condition: data.conditionKey,
        person: data.person,
        personKey: data.personKey,
        answerA: {
          text: data.a,
          impact: data.aImpact,
          values: this.parseValues('a', data),
          visualChange: data.aVisualChangeKey || null,
        },
        answerB: {
          text: data.b,
          impact: data.bImpact,
          values: this.parseValues('b', data),
          visualChange: data.bVisualChangeKey || null,
        },
      };
      questions.push(question);
    });

    return questions;
  };

  parseValues = (key: string, data: any): QuestionValues => {
    let valueNames = [
      'Relation',
      'Safety',
      'Wealth',
      'Happiness',
      'Reputation',
    ];
    let temp: any = [];
    valueNames.forEach((name) => {
      if (data[key + name]) {
        temp.push({
          type: name.toLowerCase(),
          value: data[key + name],
        });
      }
    });
    return temp;
  };
}

export default new QuestionParser();
