import { observable, decorate, action, reaction } from 'mobx';
import {
  Question,
  Answer,
  QuestionValues,
  StoreValues,
  Condition,
  Evaluation,
  GameStates
} from './types';
import QuestionParser from '../data/questionParser';
import ConditionParser from '../data/conditionParser';
import AudioPlayer from '../services/AudioPlayer';

const NUM_ROUNDS = 15;

export default class GameStore {
  constructor() {
    QuestionParser.getQuestionData('/data/questions.csv').then((result) => {
      this.setQuestions(result);
      this.setCurrentQuestion(this.playableQuestion);
      this.setQuestionLoadingState(true);
    });
    ConditionParser.getCondtionsData('/data/conditions.csv').then((result) => {
      this.setConditions(result);
    });
  }

  currentGameState: String = GameStates.SHOW_INTRO;
  questionsLoaded: boolean = false;
  conditionsLoaded: boolean = false;
  questions: Question[] = [];
  currentQuestion: Question = {
    id: 0,
    title: '',
    text: '',
    answerA: {
      text: '',
      impact: '',
      values: [
        {
          type: '',
          value: 0,
        },
      ],
    },
    answerB: {
      text: '',
      impact: '',
      values: [
        {
          type: '',
          value: 0,
        },
      ],
    },
    condition: null,
  };
  conditions: Condition[] = [];
  visuals: String[] = [];
  newlyAddedVisual: String = '';
  yearCount: number = 1;
  values: StoreValues = {
    relation: 50,
    safety: 50,
    wealth: 50,
    happiness: 50,
    reputation: 50,
  };
  prevValues: StoreValues = {
    relation: 50,
    safety: 50,
    wealth: 50,
    happiness: 50,
    reputation: 50,
  };
  answerChosen: Answer | null = null;
  gameWon: boolean = false;
  resultText: string = '';
  soundMuted: boolean = false;

  // Get a random playable question
  get playableQuestion(): Question {
    const availableQuestions = this.questions.filter((question: Question) => {
      if (question.played) return false;
      else {
        if (question.condition) {
          if (this.visuals.indexOf(question.condition) === -1) return false;
        }
        return true;
      }
    });

    return availableQuestions[
      Math.floor(Math.random() * availableQuestions.length)
    ];
  }

  // Check if all necessary rounds have been played
  yearChangeReaction = reaction(
    () => this.yearCount,
    (year) => {
      if (year === NUM_ROUNDS) {
        this.currentGameState = GameStates.SHOW_RESULT;
        this.gameWon = true;
      }
    }
  );

  setQuestions(questions: Question[]): void {
    this.questions = questions;
  }

  setCurrentQuestion(question: Question): void {
    this.currentQuestion = question;
  }

  setQuestionLoadingState(state: boolean): void {
    this.questionsLoaded = state;
  }

  setInitalValues(): void {
    this.visuals = [];
    this.newlyAddedVisual = '';
    this.yearCount = 1;
    this.values = {
      relation: 50,
      safety: 50,
      wealth: 50,
      happiness: 50,
      reputation: 50,
    };
    this.currentGameState = GameStates.SHOW_INTRO
    this.gameWon = false;
    this.answerChosen = null;
    this.resultText = '';
  }

  setConditions(conditions: Condition[]) {
    this.conditions = conditions;
  }

  updateGameValues(questionValues: QuestionValues): void {
    questionValues.forEach((v: { type: string; value: number }) => {
      switch (v.type) {
        case 'relation':
          this.values.relation += v.value;
          break;
        case 'safety':
          this.values.safety += v.value;
          break;
        case 'wealth':
          this.values.wealth += v.value;
          break;
        case 'happiness':
          this.values.happiness += v.value;
          break;
        case 'reputation':
          this.values.reputation += v.value;
          break;
        default:
          break;
      }
    });

    this.checkForGameEnding();
  }

  checkForGameEnding(): void {
    let gameEnding: boolean = false;
    this.conditions.forEach((cond: Condition) => {
      if (this.currentGameState === GameStates.SHOW_RESULT) return;

      cond.conditions.forEach((e) => {
        if (this.evaluateCondition(e)) {
          gameEnding = true;
          return;
        }
      });

      if (gameEnding) {
        this.currentGameState = GameStates.SHOW_RESULT;
        this.resultText = cond.text;
      }
    });
  }

  evaluateCondition(e: Evaluation) {
    switch (e.evaluation) {
      case '<=':
        //@ts-ignore
        if (this.values[e.type] <= e.value) {
          return true;
        }
        return false;
      case '<':
        //@ts-ignore
        if (this.values[e.type] < e.value) {
          return true;
        }
        return false;
      case '>=':
        //@ts-ignore
        if (this.values[e.type] >= e.value) {
          return true;
        }
        return false;
      case '>':
        //@ts-ignore
        if (this.values[e.type] > e.value) {
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  submitAnswer(answer: string): void {
    this.prevValues = this.values;
    // @ts-ignore
    this.answerChosen = this.currentQuestion["answer" + answer]; // tslint:disable-line
    // @ts-ignore
    this.updateGameValues(this.answerChosen.values);

    if (this.currentGameState !== GameStates.SHOW_RESULT) {
      // @ts-ignore
      this.updateVisuals(this.answerChosen.visualChange);
      this.markQuestionPlayed();
      this.showIntermediateOverlay();
    }
  }

  showIntermediateOverlay(): void {
    this.currentGameState = GameStates.SHOW_INTERMEDIATE;
  }

  showNextQuestion(): void {
    this.currentQuestion = this.nextQuestion;
    this.currentGameState = GameStates.SHOW_GAME;
  }

  markQuestionPlayed(): void {
    this.questions = this.questions.map((question: Question) => {
      if (question.id === this.currentQuestion.id) question.played = true;
      return question;
    });
  }

  get nextQuestion(): Question {
    this.yearCount++;
    return this.playableQuestion;
  }

  updateVisuals(visual: string): void {
    this.newlyAddedVisual = visual;
    if(visual) {
      this.visuals.push(visual);
      AudioPlayer.playHammerSound()
    }
    ;
  }

  updateGameState(state: string): void {
    this.currentGameState = state;
  }

  toggleSound(): void {
    AudioPlayer.toggleMute(!this.soundMuted);
    this.soundMuted = !this.soundMuted;
  }

  resetGame(): void {
    QuestionParser.getQuestionData('/data/questions.csv').then((result) => {
      this.setQuestions(result);
      this.setCurrentQuestion(this.playableQuestion);
      this.setQuestionLoadingState(true);
      this.setInitalValues();
    });
  }
}

decorate(GameStore, {
  currentGameState: observable,
  questionsLoaded: observable,
  visuals: observable,
  newlyAddedVisual: observable,
  yearCount: observable,
  currentQuestion: observable,
  answerChosen: observable,
  values: observable,
  soundMuted: observable,
  
  submitAnswer: action,
  showNextQuestion: action,
  setQuestions: action,
  setCurrentQuestion: action,
  setQuestionLoadingState: action,
  setInitalValues: action,
  checkForGameEnding: action,
  toggleSound: action,
  updateGameState: action
});
