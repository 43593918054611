import React from 'react';
import AudioPlayer from '../../services/AudioPlayer';
import './style.scss';

function AnswerButton({ id, text, onClick }) {
  function handleClick(evt) {
    evt.persist();
    evt.target.classList.add('clicked');
    AudioPlayer.playAnswerClickSound();
    setTimeout(() => {
      onClick(id);
      evt.target.classList.remove('clicked');
    }, 500);
  }

  return (
    <div className="answer-button__wrapper">
      <button className="answer-button" onClick={handleClick}>
        {text}
      </button>
    </div>
  );
}

export default AnswerButton;
