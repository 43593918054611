import { Howl, Howler } from 'howler';
import WriteSound1 from '../assets/audio/write1.mp3';
import WriteSound2 from '../assets/audio/write2.mp3';
import WriteSound3 from '../assets/audio/write3.mp3';
import ClickSound from '../assets/audio/click1.mp3';
import BgMusic from '../assets/audio/MedievalStoryteller.mp3';
import HammerSound1 from '../assets/audio/hammer.mp3';
import HammerSound2 from '../assets/audio/nail.mp3';

class AudioPlayer {
  constructor() {
    this.answerClickSounds = [WriteSound1, WriteSound2, WriteSound3];
    this.constructionSounds = [HammerSound1, HammerSound2];
  }

  playBgMusic() {
    new Howl({
      src: [BgMusic],
      autoplay: true,
      loop: true,
      volume: 0.1,
    })
      .fade(0, 0.1, 3000)
      .play();
  }

  playAnswerClickSound() {
    const random = Math.floor(
      Math.random() * Math.floor(this.answerClickSounds.length)
    );

    new Howl({
      src: [this.answerClickSounds[random]],
    }).play();
  }

  playButtonClickSound() {
    new Howl({
      src: [ClickSound],
    }).play();
  }

  playHammerSound() {
    const random = Math.round(Math.random())

    new Howl({
      src: [this.constructionSounds[random]],
    }).play();
  }

  toggleMute(mute) {
    Howler.mute(mute);
  }
}

export default new AudioPlayer();
