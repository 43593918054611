import React from 'react';
import { MdClose } from 'react-icons/md';
import AudioPlayer from '../../services/AudioPlayer';
import { GameStates } from '../../stores/types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import './style.scss';

const MuteButton = observer(({ handleClose }) => {
  const { gameStore } = useStores();

  function handleClick() {
    AudioPlayer.playButtonClickSound();
    handleClose();
  }

  const classes = classNames({
    'close-button': true,
    'fade-in': gameStore.currentGameState === GameStates.SHOW_GAME,
    'fade-out': gameStore.currentGameState === GameStates.SHOW_INTERMEDIATE,
  });

  return (
    <button className={classes} onClick={handleClick}>
      <MdClose />
    </button>
  );
});

export default MuteButton;
