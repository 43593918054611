import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import classNames from 'classnames';
import RelationBanner from '../../assets/images/stift_banner.png';
import RelationIcon from '../../assets/images/stift_icon_framed_line.png';
import SafetyBanner from '../../assets/images/sicherheit_banner.png';
import SafetyIcon from '../../assets/images/sicherheit_icon_framed_line.png';
import WealthBanner from '../../assets/images/reichtum_banner.png';
import WealthIcon from '../../assets/images/reichtum_icon_framed_line.png';
import HappinessBanner from '../../assets/images/zufriedenheit_banner.png';
import HappinessIcon from '../../assets/images/zufriedenheit_icon_framed_line.png';
import ReputationBanner from '../../assets/images/ansehen_banner.png';
import ReputationIcon from '../../assets/images/ansehen_icon_framed_line.png';
import './style.scss';

const StatusDisplay = observer((props) => {
  const { gameStore } = useStores();

  function convertMargin(value) {
    return (value - 100) * -1;
  }

  const bannerData = [
    {
      banner: RelationBanner,
      icon: RelationIcon,
      value: gameStore.values.relation,
    },
    { banner: SafetyBanner, icon: SafetyIcon, value: gameStore.values.safety },
    { banner: WealthBanner, icon: WealthIcon, value: gameStore.values.wealth },
    {
      banner: HappinessBanner,
      icon: HappinessIcon,
      value: gameStore.values.happiness,
    },
    {
      banner: ReputationBanner,
      icon: ReputationIcon,
      value: gameStore.values.reputation,
    },
  ];

  const wrapperClasses = classNames({
    'status-display__wrapper': true,
    'with-values': props.withValues,
  });

  const iconClasses = classNames({
    'status-display__banner-icon': true,
    'animated-icon': props.withValues,
    'animated-icon-delayed': !props.withValues,
  });

  return (
    <div className={wrapperClasses}>
      {/* ----- Pole ----- */}
      <div className="status-display__pole">
        <div className="status-display__pole--left"></div>
        <div className="status-display__pole--middle"></div>
        <div className="status-display__pole--right"></div>
      </div>

      <div className="status-display__banners-wrapper">
        {bannerData.map((banner) => {
          return (
            <div
              className="status-display__banner-container"
              style={{
                backgroundImage: `url(${banner.banner})`,
                marginTop: '4px',
                marginBottom: '4px',
              }}
            >
              <div
                className={iconClasses}
                style={{
                  backgroundImage: `url(${banner.icon})`,
                  marginTop: `${convertMargin(banner.value)}%`,
                }}
              ></div>
            </div>
          );
        })}
      </div>
      {/* ----- Plus - Minus ----- */}
      <div className="status-display__changed-values">
        <div className="status-display__value-container">
          <span>{props.changedValues?.relation}</span>
        </div>
        <div className="status-display__value-container">
          <span>{props.changedValues?.safety}</span>
        </div>
        <div className="status-display__value-container">
          <span>{props.changedValues?.wealth}</span>
        </div>
        <div className="status-display__value-container">
          <span>{props.changedValues?.happiness}</span>
        </div>
        <div className="status-display__value-container">
          <span>{props.changedValues?.reputation}</span>
        </div>
      </div>
    </div>
  );
});

export default StatusDisplay;
