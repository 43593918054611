import React, { useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useStores } from '../../hooks/use-stores';
import MuteButton from '../../components/MuteButton';
import CloseButton from '../../components/CloseButton';
import ResultOverlay from '../../components/ResultOverlay';
import IntroOverlay from '../../components/IntroOverlay';
import ImpactDisplay from '../../components/ImpactDisplay';
import CloseGameAlert from '../../components/CloseGameAlert';
import Quiz from '../../components/Quiz';
import { GameStates } from '../../stores/types';
import './style.scss';

const Game = observer(() => {
  const [showCloseAlert, setShowCloseAlert] = useState(false);
  const history = useHistory();
  const {
    gameStore,
    gameStore: { currentGameState },
  } = useStores();

  function handleCloseClick() {
    setShowCloseAlert(true);
  }

  function handleCloseGame() {
    gameStore.resetGame();
    history.push('/');
  }

  function handleCloseAlert() {
    setShowCloseAlert(false);
  }

  function handleCloseIntro() {
    gameStore.updateGameState(GameStates.SHOW_GAME);
  }

  function renderComponents() {
    switch (currentGameState) {
      case GameStates.SHOW_INTRO:
        return <IntroOverlay closeIntro={handleCloseIntro} />;
      case GameStates.SHOW_RESULT:
        return <ResultOverlay />;
      case GameStates.SHOW_MAP:
        return; // Add later
      default:
        break;
    }
  }

  if (!gameStore.questionsLoaded)
    return (
      <div className="screen">
        <ClipLoader size={50} color={'#000'} loading={true} />
      </div>
    );

  const leftCloudClasses = classNames({
    'clouds--left': true,
    'slide-in--left': currentGameState === GameStates.SHOW_GAME,
    'slide-out--left': currentGameState === GameStates.SHOW_INTERMEDIATE,
  });

  const rightCloudClasses = classNames({
    'clouds--right': true,
    'slide-in--right': currentGameState === GameStates.SHOW_GAME,
    'slide-out--right': currentGameState === GameStates.SHOW_INTERMEDIATE,
  });

  return (
    <div className="screen">
      <ImpactDisplay />
      <div className={leftCloudClasses}></div>
      <div className={rightCloudClasses}></div>
      <CloseButton handleClose={handleCloseClick} />
      <MuteButton left />
      <Quiz />
      <CloseGameAlert
        show={showCloseAlert}
        toHome={handleCloseGame}
        closeAlert={handleCloseAlert}
      />
      {renderComponents()}
    </div>
  );
});

export default Game;
