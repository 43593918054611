import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './screens/home';
import Game from './screens/game';
import AudioPlayer from './services/AudioPlayer';

export default function App() {
  AudioPlayer.playBgMusic();
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the FIRST one that matches the current URL. */}
      <Switch>
        <Route path="/game">
          <Game />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}
