import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import Button from '../Button';
import Map from '../Map';
import StatusDisplay from '../StatusDisplay';
import { GameStates } from '../../stores/types';
import MinusImage from '../../assets/images/pfeil_unten.png';
import PlusImage from '../../assets/images/pfeil_oben.png';

import './style.scss';

const ImpactDisplay = observer(() => {
  const {
    gameStore: { answerChosen, currentGameState },
    gameStore,
  } = useStores();

  function handleShowNextQuestion() {
    gameStore.showNextQuestion();
  }

  function getChangedValues() {
    if (!answerChosen) return;

    const values = {
      relation: '',
      safety: '',
      wealth: '',
      happiness: '',
      reputation: '',
    };

    answerChosen.values.forEach((val) => {
      values[val.type] = val.value > 0 
        ? <img className="impact-display__changed-icon minus" src={PlusImage} alt=""/> 
        : <img className="impact-display__changed-icon plus" src={MinusImage} alt=""/>;
    });

    return values;
  }

  const impactClasses = classNames({
    'impact-display': true,
    'fade-in': currentGameState === GameStates.SHOW_INTERMEDIATE,
    'fade-out': currentGameState === GameStates.SHOW_GAME,
  });

  return (
    <div className={impactClasses}>
      <div className="impact-display__content-wrapper">
        <div className="impact-display__info-wrapper">
          <h1 className="impact-display__headline">Auswirkung</h1>
          <StatusDisplay
            changedValues={getChangedValues()}
            withValues
          ></StatusDisplay>
          <p className="impact-display__text imp">{answerChosen?.impact}</p>
        </div>
        <Map></Map>
      </div>
      <div className="impact-display__button-wrapper">
        <Button onClick={handleShowNextQuestion}>Weiter</Button>
      </div>
    </div>
  );
});

export default ImpactDisplay;
