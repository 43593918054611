import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';
import Button from '../../components/Button';
import { GameStates } from '../../stores/types';
import './style.scss';
import StatusDisplay from '../StatusDisplay';
import BildBanner from '../../assets/images/jahresbanner.png';

const ResultOverlay = observer(() => {
  const {
    gameStore: { gameWon, resultText, values },
    gameStore,
  } = useStores();
  const history = useHistory();

  function handleGameRestart() {
    gameStore.resetGame();
    gameStore.updateGameState(GameStates.SHOW_GAME);
  }

  function handleGoToHome() {
    gameStore.resetGame();
    history.push('/');
  }

  function renderValues() {
    const names = {
      relation: ["Deine Beziehung zum Stift ist zu gut!", "Deine Beziehung zum Stift ist zu schlecht!"],
      safety: ["Das Militär ist zu stark!", "Die Sicherheit in der Stadt ist zu gering!"],
      wealth: ["Die Stadt ist zu wohlhabend!", "Das Geld ist nicht ausreichend!"],
      happiness: ["Zufriedenheit in der ganzen Stadt!", "Die Stadt ist unzufrieden!"],
      reputation: ["Dein Ansehen ist zu hoch!", "Dein Ansehen ist zu niedrig!"],
    };
    
    const critical = Object.keys(values).find(key => values[key] >= 100 || values[key] <= 0);
    
    if (values[critical] >= 100) return <p className="overlay__value highlight">{names[critical][0]}</p>;
    else if (values[critical] <= 0) return <p className="overlay__value highlight">{names[critical][1]}</p>;
  }

  return (
    <div className="overlay">
      <div className="overlay__content-wrapper">
        {gameWon ? (
          <>
          <div className="home__headline-wrapper">
            <img src={BildBanner} alt="" />
            <div className="home__headline-container">
              <h1 className="winning-title">Glückwunsch!</h1>
            </div>
          </div>
            <StatusDisplay></StatusDisplay>
            <h1 className="overlay__headline">Spiel gewonnen</h1>
            <p className="overlay__text">
              Nach 15 erfolgreichen Jahren als Bürgermeister der Stadt Kempten,
              gibst du dein Amt an deinen Nachfolger ab und setzt dich zur Ruhe.
            </p>
          </>
        ) : (
          <>
            <StatusDisplay></StatusDisplay>
            <h1 className="overlay__headline" size={1.4}>
              Spiel verloren
            </h1>
            {
              renderValues()
            }
            <p className="overlay__text">{resultText}</p>
          </>
        )}
        <div className="overlay__button-wrapper">
          <Button onClick={handleGoToHome}>Spiel schließen</Button>
          <Button onClick={handleGameRestart}>Neues Spiel</Button>
        </div>
      </div>
    </div>
  );
});

export default ResultOverlay;
