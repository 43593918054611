import React, {useState} from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import RelationIcon from '../../assets/images/stift_icon_framed.png';
import SafetyIcon from '../../assets/images/sicherheit_icon_framed.png';
import WealthIcon from '../../assets/images/reichtum_icon_framed.png';
import HappinessIcon from '../../assets/images/zufriedenheit_icon_framed.png';
import ReputationIcon from '../../assets/images/ansehen_icon_framed.png';
import ValuesExplanationImage from '../../assets/images/value_explanation.png';
import Button from '../../components/Button';
import './style.scss';

const IntroOverlay = observer((props) => {
  const [showFirstPage, toggleSecondPage] = useState(true);
  function handleCloseIntro() {
    props.closeIntro();
  }

  const firstPageClasses = classNames({
    'overlay__content-wrapper': true,
    'hidden': !showFirstPage
  })

  const secondPageClasses = classNames({
    'overlay__content-wrapper': true,
    'hidden': showFirstPage
  })

  return (
    <div className="overlay">
      <div className={firstPageClasses}>
        <h1 className="overlay__headline">Anleitung</h1>
        <p className="overlay__text">
          Du bist Bürgermeister von Kempten und möchtest 15 Runden im Amt bleiben. Dazu musst du versuchen, die folgenden Werte im Gleichgewicht zu halten:
        </p>
        <div className="intro__grid">
          <img className="intro__value-icon" src={RelationIcon} alt="" />
          <p className="intro__value-description">Beziehung zum Stift</p>
          <img className="intro__value-icon" src={SafetyIcon} alt="" />
          <p className="intro__value-description">Sicherheit</p>
          <img className="intro__value-icon" src={WealthIcon} alt="" />
          <p className="intro__value-description">Reichtum</p>
          <img className="intro__value-icon" src={HappinessIcon} alt="" />
          <p className="intro__value-description">Zufriedenheit</p>
          <img className="intro__value-icon" src={ReputationIcon} alt="" />
          <p className="intro__value-description">Ansehen</p>
        </div>
        <br />
        <div className="intro__button-wrapper">
          <Button onClick={toggleSecondPage}>Weiter</Button>
        </div>
      </div>
      <div className={secondPageClasses}>
        <h1 className="overlay__headline">Anleitung</h1>
        <p className="overlay__text">
          Du musst in verschiedenen Situationen entscheiden, wie du dich als Bürgermeister von Kempten verhalten würdest. Jede Entscheidung hat Auswirkung auf einen oder mehrere Werte.
        </p>
        <div className="intro__image-wrapper">
          <img className="intro__image" src={ValuesExplanationImage} alt=""/>
        </div>
        <p className="overlay__text">
          Die Banner zeigen dir an, wie hoch der jeweilige Wert gerade ist. Sollte einer Werte zu hoch oder zu niedrig sein, dann ist das Spiel vorbei.
        </p>
        <br />
        <div className="intro__button-wrapper">
          <Button onClick={handleCloseIntro}>Starten</Button>
        </div>
      </div>
    </div>
  );
});

export default IntroOverlay;
