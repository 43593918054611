import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import AudioPlayer from '../../services/AudioPlayer';
import SoundActiveIcon from '../../assets/images/sound_active_icon.svg';
import SoundMutedIcon from '../../assets/images/sound_inactive_icon.svg';
import { GameStates } from '../../stores/types';
import './style.scss';

const MuteButton = observer((props) => {
  const { gameStore } = useStores();

  function handleClick() {
    AudioPlayer.playButtonClickSound();
    gameStore.toggleSound();
  }

  const classes = classNames({
    'mute-button': true,
    left: props.left,
    'fade-in':
      props.left && gameStore.currentGameState === GameStates.SHOW_GAME,
    'fade-out':
      props.left && gameStore.currentGameState === GameStates.SHOW_INTERMEDIATE,
  });

  return (
    <button className={classes} onClick={handleClick}>
      {gameStore.soundMuted ? (
        <img src={SoundMutedIcon} alt="" />
      ) : (
        <img src={SoundActiveIcon} alt="" />
      )}
    </button>
  );
});

export default MuteButton;
