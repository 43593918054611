import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import BaseImage from '../../assets/images/map/city.png';
import MonasteryImage from '../../assets/images/map/monastery.png';
import WallImage from '../../assets/images/map/wall_towers.png';
import SmallChurchImage from '../../assets/images/map/st_mang_small.png';
import ChurchImage from '../../assets/images/map/st_mang.png';
import ChapelImage from '../../assets/images/map/michaelskapelle.png';
import GuildHouseImage from '../../assets/images/map/weaver.png';
import TownHallImage from '../../assets/images/map/kornhaus_wood.png';
import TownHallStoneImage from '../../assets/images/map/kornhaus.png';
import WallUpgradeBigImage from '../../assets/images/map/wall_vorstadt.png';
import WallUpgradeSmallImage from '../../assets/images/map/parapetwalk.png';
import IllerOldImage from '../../assets/images/map/Iller_old.png';
import IllerNewImage from '../../assets/images/map/Iller_new.png';
import PaperMillImage from '../../assets/images/map/paper_mill.png';
import MintHouseImage from '../../assets/images/map/muenzhaus.png';
import './style.scss';

const Map = observer(() => {
  const {
    gameStore: { visuals, newlyAddedVisual },
  } = useStores();

  function getBaseClasses() {
    if (newlyAddedVisual) return 'map__base-image opacity';
    return 'map__base-image';
  }

  const getIllerClasses = classNames({
    'map__overlay-image': true,
    opacity: newlyAddedVisual,
  });

  const mapData = {
    base: <img className={getBaseClasses()} src={BaseImage} alt="" />,
    smallChurch: (
      <img className={getBaseClasses()} src={SmallChurchImage} alt="" />
    ),
    monastry: <img className={getBaseClasses()} src={MonasteryImage} alt="" />,
    river: (
      <img
        className={getIllerClasses}
        key={'iller_old'}
        src={IllerOldImage}
        alt=""
      />
    ),
    parapet: null,
    wall: null,
    wallUrban: null,
    church: null,
    chapel: null,
    guild: null,
    townHall: null,
    mint: null,
    paper: null,
  };

  function renderMap() {
    const keysToRender = visuals.slice();

    if (keysToRender === []) return;

    keysToRender.forEach((visual) => {
      let classes = classNames({
        'map__overlay-image': true,
        opacity: newlyAddedVisual && visual !== newlyAddedVisual,
        'new-change': visual === newlyAddedVisual,
      });

      switch (visual) {
        case 'wall':
          mapData.wall = (
            <img className={classes} key={visual} src={WallImage} alt="" />
          );
          break;
        case 'church':
          mapData.church = (
            <img className={classes} key={visual} src={ChurchImage} alt="" />
          );
          break;
        case 'chapel':
          mapData.chapel = (
            <img className={classes} key={visual} src={ChapelImage} alt="" />
          );
          break;
        case 'bar':
          mapData.chapel = (
            <img className={classes} key={visual} src={ChapelImage} alt="" />
          );
          break;
        case 'guild_house':
          mapData.guild = (
            <img
              className={classes}
              key={visual}
              src={GuildHouseImage}
              alt=""
            />
          );
          break;
        case 'town_hall':
          mapData.townHall = (
            <img className={classes} key={visual} src={TownHallImage} alt="" />
          );
          break;
        case 'town_hall_stone':
          mapData.townHall = (
            <img
              className={classes}
              key={visual}
              src={TownHallStoneImage}
              alt=""
            />
          );
          break;
        case 'wall_upgrade_big':
          mapData.wall = (
            <img
              className={classes}
              key={visual}
              src={WallUpgradeBigImage}
              alt=""
            />
          );
          break;
        case 'wall_upgrade_small':
          mapData.parapet = (
            <img
              className={classes}
              key={visual}
              src={WallUpgradeSmallImage}
              alt=""
            />
          );
          break;
        case 'iller_moved':
          mapData.river = (
            <img className={classes} key={visual} src={IllerNewImage} alt="" />
          );
          break;
        case 'mint_house':
          mapData.mint = (
            <img className={classes} key={visual} src={MintHouseImage} alt="" />
          );
          break;
        case 'paper_mill':
          mapData.paper = (
            <img className={classes} key={visual} src={PaperMillImage} alt="" />
          );
          break;
        default:
          break;
      }
    });

    return Object.values(mapData);
  }

  return (
    <div className="map__wrapper">
      <div className="map">{renderMap()}</div>
    </div>
  );
});

export default Map;
