import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import MuteButton from '../../components/MuteButton';
import LogoSparkasse from '../../assets/images/logos/sparkasse-allgaeu.png';
import LogoStiftung from '../../assets/images/logos/bayerische-sparkassenstiftung.png';
import LogoMuseum from '../../assets/images/logos/museum-logo.png';
import ImageMayor from '../../assets/images/buergermeister.jpg';
import ImagePriest from '../../assets/images/fuerstabt.jpg';
import AudioPlayer from '../../services/AudioPlayer';
import BildBanner from '../../assets/images/jahresbanner.png';
import './style.scss';

export default function Home() {
  const history = useHistory();

  function handleGameStart() {
    AudioPlayer.playButtonClickSound();
    history.push('/game');
  }

  return (
    <>
      <img className="home__logo" alt="Logo Museum Kempten" src={LogoMuseum} />
      <MuteButton />
      <div className="screen">
        <div className="home__content-wrapper">
          <div className="home__image-container">
            <img className="home__image--left" src={ImagePriest} alt="" />
            <img className="home__image--right" src={ImageMayor} alt="" />
          </div>
          <div className="home__headline-wrapper">
            <img src={BildBanner} alt="" />
            <div className="home__headline-container">
              <h1 className="home__headline">Herrscher von Kempten</h1>
            </div>
          </div>
          <p className="home__text">
            Das Allgäu im Mittelalter. Die kleine protestantische Reichstadt
            Kempten kämpft, umgeben vom Gebiet eines mächtigen katholischen
            Fürstabtes, um Macht und Ansehen. Bisher konnten der Rat der Stadt
            und seine Bürgermeister den Abt im Zaum halten. Schaffst du das
            auch?
          </p>
          <Button pulse onClick={handleGameStart}>
            Spiel starten
          </Button>
        </div>
      </div>
      <div className="sponsors">
        <p className="sponsors__text">Mit freundlicher Unterstützung von</p>
        <img
          className="sponsors__image"
          src={LogoSparkasse}
          alt="Logo Sparkasse"
        />
        <img
          className="sponsors__image"
          src={LogoStiftung}
          alt="Logo Sparkassenstiftung"
        />
      </div>
    </>
  );
}
