import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../hooks/use-stores';
import AnswerButton from '../../components/AnswerButton';
import StatusDisplay from '../../components/StatusDisplay';
import { GameStates } from '../../stores/types';
import './style.scss';

const Game = observer(() => {
  const {
    gameStore,
    gameStore: { currentGameState },
  } = useStores();

  function handleAnswerClick(answer) {
    gameStore.submitAnswer(answer);
  }

  const quizClasses = classNames({
    quiz__container: true,
    'slide-in--top': currentGameState === GameStates.SHOW_GAME,
    'slide-out--top': currentGameState === GameStates.SHOW_INTERMEDIATE,
  });

  function renderAnswerButtons() {
    const version = Math.round(Math.random());
    if (version) {
      return (
        <div className="quiz__answer-buttons-wrapper">
          <AnswerButton
            onClick={handleAnswerClick}
            id="A"
            text={gameStore.currentQuestion.answerA.text}
          />
          <AnswerButton
            onClick={handleAnswerClick}
            id="B"
            text={gameStore.currentQuestion.answerB.text}
          />
        </div>
      );
    }
    return (
      <div className="quiz__answer-buttons-wrapper">
        <AnswerButton
          onClick={handleAnswerClick}
          id="B"
          text={gameStore.currentQuestion.answerB.text}
        />
        <AnswerButton
          onClick={handleAnswerClick}
          id="A"
          text={gameStore.currentQuestion.answerA.text}
        />
      </div>
    );
  }

  return (
    <div className={quizClasses}>
      <StatusDisplay />
      <div className="quiz__outline">
        <div className="quiz__year-banner">
          <p className="quiz__year-count">Runde {gameStore.yearCount}/15</p>
        </div>
        <div className="quiz__wrapper">
          <div className="quiz__question-wrapper">
            <h1 className="quiz__question-title">
              {gameStore.currentQuestion.title}
            </h1>
            <p className="quiz__question-text">
              {gameStore.currentQuestion.text}
            </p>
          </div>
          {renderAnswerButtons()}
        </div>
      </div>
    </div>
  );
});

export default Game;
