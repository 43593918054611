import csvLoader from './csvLoader';
import { Condition, Evaluation } from '../stores/types';

// Parse the loose conditions
class ConditionParser {
  async getCondtionsData(url: string): Promise<Condition[]> {
    const content = await csvLoader.getCsvData(url);
    return this.parseConditions(content.data);
  }

  parseConditions = (conditionData: any): Condition[] => {
    let conditions: Condition[] = [];

    conditionData.forEach((data: any) => {
      let condition: any = {
        text: data.text,
        conditions: this.parseEvaluations(data),
      };
      conditions.push(condition);
    });

    return conditions;
  };

  parseEvaluations(data: any): Evaluation[] {
    let evaluations: any = [];
    if (data.condition) {
      evaluations.push({
        type: data.conditionKey,
        value: data.value,
        evaluation: data.evaluation,
      });
    }
    if (data.condition2) {
      evaluations.push({
        type: data.conditionKey2,
        value: data.value2,
        evaluation: data.evaluation2,
      });
    }
    return evaluations;
  }
}

export default new ConditionParser();
