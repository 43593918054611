import Papa from 'papaparse';

class CsvToObjParser {
  fetchCsv = (url: string) => {
    return fetch(url).then(function (response: any) {
      let reader = response.body.getReader();
      let decoder = new TextDecoder('utf-8');

      return reader.read().then(function (result: any) {
        return decoder.decode(result.value);
      });
    });
  };

  getCsvData = async (url: string) => {
    let csvData: string = await this.fetchCsv(url);
    return Papa.parse(csvData, {
      header: true,
      // Remove the first line of the CSV
      // We need the second line for headings
      beforeFirstChunk: function (chunk) {
        var rows = chunk.split('\n');
        rows = rows.slice(1);
        return rows.join('\n');
      },
      dynamicTyping: true,
    });
  };
}

export default new CsvToObjParser();
